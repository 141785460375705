import { Bar, DigitalHumanContainer, MainContainer } from '@Containers'
import { IServices } from '@Entities/Interfaces/IServices'
import { Actor, DigitalHumanState, Stage, Translation } from '@Entities/Enums'
import { useRef, useState } from 'react'
import {
    Bubble,
    BubbleState,
    ButtonState,
    CheckButton,
    DropdownAlignment,
    IconLabelButton,
    IconVector,
} from '@Elements'
import { DropdownController, DropdownTemplate } from './ContentControllers/DropdownController'
import { t } from 'i18next'
import { I18n } from '@Translation'

export function InitialController(services: IServices) {
    const start = async () => {
        services.BackendManager.start();
        services.InterfaceManager.Stage = Stage.LOADING
    }

    const [containerVisibility, setContainerVisibility] = useState(true)
    const [isStartDisabled, setIsStartDisabled] = useState(true)
    const [Language, setLang] = useState('en')
    const startRef = useRef<HTMLDivElement | null>(null)
    services.InterfaceManager.onStageChanged((state) => setContainerVisibility(state === Stage.INITIAL))

    I18n.on('languageChanged', (lang) =>
        setLang(lang)
    )
    const handleClick = () => {
        if (isStartDisabled) startRef.current?.scrollIntoView({ behavior: 'smooth' })
        setIsStartDisabled((isStartDisabled) => !isStartDisabled)
    }

    return (
        containerVisibility ? 
        <DigitalHumanContainer State={DigitalHumanState.CHAT} >
                <MainContainer
                    key="InitialController"
                    Name={`InitialController`}
                    Visible={containerVisibility}
                    Top={[
                        <Bar
                            Name="topActionBar"
                            Right={[
                                <DropdownController
                                    Services={services}
                                    key="LanguageSelector"
                                    Icon={IconVector.UNION}
                                    IconName="LanguageSelector"
                                    Alignment={DropdownAlignment.BOTTOM_LEFT}
                                    Template={DropdownTemplate.LANGUAGE_SELECTION}
                                />,
                                <DropdownController
                                    Services={services}
                                    key="SettingControl"
                                    Icon={IconVector.TEXT_SIZE}
                                    IconName="SettingControl"
                                    Alignment={DropdownAlignment.BOTTOM_LEFT}
                                    Template={DropdownTemplate.INTERFACE_SIZE_NO_BAR}
                                />,
                            ]}
                        />,
                    ]}
                    Main={[
                    <div className="contentArea" style={{ backgroundColor: 'transparent' }}>
                            <div
                                className="contentScrollArea scrollTop"
                                id="contentScrollArea"
                                style={{ backgroundColor: 'transparent', paddingLeft: 0 }}
                            >
                                <div className="contentGroup">
                                    {t(Translation.HOMESCREEN_BUBBLES, { returnObjects: true }).map((label, idx) => (
                                        <Bubble Actor={Actor.AVATAR} Label={label.text} key={`initial_bubbles_${idx}`} State={BubbleState.ACTIVE} />
                                    ))}
                                    <CheckButton
                                        State={ButtonState.DEFAULT}
                                        Name="editorial"
                                        Label={Translation.HOMESCREEN_AGREEMENT}
                                        Checked={false}
                                        OnClick={() => handleClick()}
                                    />
                                    <div
                                        ref={startRef}
                                        style={{ visibility: isStartDisabled ? 'hidden' : 'visible' }}
                                        className="editorial"
                                    >
                                        <IconLabelButton
                                            Label={Translation.HOMESCREEN_START_BUTTON}
                                            IconVector={IconVector.CHEVRON_RIGHT}
                                            State={ButtonState.DEFAULT}
                                            OnClick={() => start()}
                                            Name="editorial"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>,
                    ]}
                    Bottom={[]}
                />
            </DigitalHumanContainer>
        : null
    )
}
