import { IElement } from '@Entities/Interfaces'
import { ElementType, PlayableState } from '@Entities/Enums'
import { Expose } from 'class-transformer'

export abstract class AbstractElement implements IElement {
    abstract elementType: ElementType
    abstract type: string
    abstract name: string
    
    @Expose()
    id: string = '';

    @Expose()
    state: PlayableState = PlayableState.PENDING;


}

