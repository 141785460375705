import { DataType } from '@Entities/Enums/DataType'
import { Message, UneeqSession, UserMessage } from '@Entities'
import { Subject } from 'rxjs'
import { io } from 'socket.io-client'
import { BackendState } from '@Entities/Enums'
import { ConfigManager } from './ConfigManager'
import { DigitalHumanConfig } from '@Entities/Models/DigitalHumanConfig'
import { I18n } from '@Translation'

export class BackendManager{
    private config: DigitalHumanConfig = ConfigManager.getInstance().config;
    private readonly url: string;
    private session?: UneeqSession;
    private _backendState: BackendState = BackendState.AVAILABLE;
    private socket: any;

    set backendState(value: BackendState) {
        this.stateObservable.next(value)
        this._backendState = value
    }

    //function Using rxjs to monitor BackendState with a Subject
    private stateObservable = new Subject<BackendState>();
    public onStateChanged = (callback: (state: BackendState) => void):void => {
        this.stateObservable.subscribe(callback);
    }

    //Singleton
    private static instance: BackendManager;
    public static getInstance(): BackendManager {
        if(!BackendManager.instance)
            BackendManager.instance = new BackendManager();
        return BackendManager.instance
    }

    private constructor() {
        this.url = this.config.backend.Url;
    }

    public start(){
        this.socket = io(this.url, {
            transports: ['websocket'],
            query: {
                'personaId': this.config.backend.PersonaId,
                'language': I18n.language,
                'env': this.config.backend.Env.id
            }
        });
    }

    public async getUneeqSession(): Promise<UneeqSession> {
        if(!this.session)
            this.session = await this.initializeSession();
        return this.session;
    }

    private async initializeSession() : Promise<UneeqSession> {
        this.socket.emit('CreateSession')
        return new Promise<UneeqSession>(async (resolve) => {
            this.socket.on('SessionCreated', (session: UneeqSession) => {
               resolve(session as UneeqSession)
            })
        });
    }

    private async transmitUserMessage(userMessage: UserMessage, sessionId?: string) {
        userMessage.sessionId = sessionId
        userMessage.language = I18n.language
        this.socket.emit('query', userMessage)
    }

    public async sendAudio(data:string, sessionId:string){
        this.backendState = BackendState.QUERYING
        const userMessage = new UserMessage();
        userMessage.dataType = DataType.AUDIO
        userMessage.data = data
        this.transmitUserMessage(userMessage, sessionId).then( () => console.info(`Audio recorded sent to the backend`))
    }

    public async sendEvent(eventName:string, sessionId?:string, page?: string){
        this.backendState = BackendState.QUERYING
        const userMessage = new UserMessage();
        userMessage.dataType = DataType.EVENT
        userMessage.data = eventName
        if(page) userMessage.page = page
        this.transmitUserMessage(userMessage, sessionId).then( () => console.info(`EventName ${eventName} sent to the backend`))
    }

    public async sendText(text:string, sessionId:string, page?: string){
        this.backendState = BackendState.QUERYING
        const userMessage = new UserMessage()
        userMessage.dataType = DataType.TEXT
        userMessage.data = text
        userMessage.page = page
        this.transmitUserMessage(userMessage, sessionId).then( () => console.info(`Text sent to the backend: ${text} `))
    }

    public async sendParams(keyValuePairs:{key:string, value:string}[], sessionId:string, page?: string){
        const jsonObject: any = {};
        keyValuePairs.forEach(pair => {jsonObject[pair.key] = pair.value});
        this.backendState = BackendState.QUERYING
        const userMessage = new UserMessage()
        userMessage.dataType = DataType.PARAMS
        userMessage.data = jsonObject
        userMessage.page = page
        this.transmitUserMessage(userMessage, sessionId).then( () => console.info(`Params sent to the backend:  `, jsonObject))
    }


    private replayDebounceTimerId: NodeJS.Timeout | undefined;

    /**
     * Replays a historical record.
     *
     * @param {Message | undefined} record - The historical record to replay.
     * @param {string} sessionId - The session ID.
     */
    public replayRecord(record: Message | undefined, sessionId:string){
    }

}