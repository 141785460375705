import React from 'react'
import { Actor } from '@Entities/Enums'
import { Label } from '../Label/Label'
import { Highlight } from '@Containers'

export enum BubbleState {
    ACTIVE = 'active',
    DISABLED = 'disabled',
}

export interface BubbleProps {
    Label: String,
    State: BubbleState
    Actor: Actor
}

export class Bubble extends React.Component<
    BubbleProps,
    {
        State: BubbleState
        Highlighted: boolean
        Ref: React.RefObject<HTMLDivElement>
       
    }
> {
    constructor(props: BubbleProps) {
        super(props)
        this.state = { State: this.props.State, Highlighted: false, Ref: React.createRef()  }
        
    }

    render(): React.ReactNode {
        if (this.props.State === BubbleState.DISABLED) return null

        return (
            this.props.Label && (
                <Highlight
                    Highlighted={this.state.Highlighted}
                    Name="box"
                    Child={
                        <div ref={this.state.Ref} className={`bubble ${this.props.Actor}`}>
                            <Label Text={this.props.Label} />
                        </div>
                    }
                />
            )
        )
    }
}
