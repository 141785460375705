import { DigitalHumanState, Stage } from '@Entities/Enums'
import { Subject } from 'rxjs'

export class InterfaceManager {

    private _DigitalHumanStateObservable = new Subject<{previous: DigitalHumanState, current:DigitalHumanState}>();
    private _StageObservable: Subject<Stage> = new Subject<Stage>();

    private _DigitalHumanState = [DigitalHumanState.AVATAR]
    private _Stage: Stage = Stage.INITIAL

    handleDigitalHumanState(callback: (props: {previous: DigitalHumanState, current: DigitalHumanState }) => void) {
        this._DigitalHumanStateObservable.subscribe(callback);
    }
    verifyDigitalHumanState = () => {
        this._DigitalHumanStateObservable.next({
            previous: this.getPreviousDigitalHumanState(),
            current: this.getCurrentDigitalHumanState()
        });
    }

    onStageChanged(callback: (state:Stage) => void) {
        this._StageObservable.subscribe(callback);
    }

    set Stage(value: Stage) {
        this._StageObservable.next(value);
        this._Stage = value
    }

    setDigitalHumanState = (value: DigitalHumanState) => {
        this._DigitalHumanStateObservable.next({ previous: this._DigitalHumanState[this._DigitalHumanState.length - 1], current: value });
        this._DigitalHumanState.push(value)
    }

    changePreviousDigitalHumanState = (value: DigitalHumanState) => {
        if(this._DigitalHumanState.length < 2) return
        this._DigitalHumanState[this._DigitalHumanState.length - 2] = value
    }

    getStateStack(): DigitalHumanState[] {
        return this._DigitalHumanState
    }

    getCurrentDigitalHumanState(): DigitalHumanState {
        return this._DigitalHumanState[this._DigitalHumanState.length - 1]
    }

    getPreviousDigitalHumanState(): DigitalHumanState {
        if(this._DigitalHumanState.length === 1) return DigitalHumanState.AVATAR
        return this._DigitalHumanState[this._DigitalHumanState.length - 2]
    }

    get DigitalHumanState(): DigitalHumanState[] {
        return this._DigitalHumanState;
    }

    get Stage(): Stage {
        return this._Stage
    }

    private static instance: InterfaceManager;

    public static getInstance(): InterfaceManager {
        if(!InterfaceManager.instance)
            InterfaceManager.instance = new InterfaceManager();
        return InterfaceManager.instance
    }

    private constructor() { }

}