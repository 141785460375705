import { Label, ButtonState } from '@Elements'
import { Translation } from '@Entities/Enums'
import React, { ChangeEventHandler } from 'react'

export interface RadioButtonProps {
    State: ButtonState
    Label: Translation | string
    Checked?: boolean
    InputName?: string
    InputValue?: string
    Name?: string
    OnChangeHandler?: ChangeEventHandler<HTMLInputElement>
}

export class RadioButton extends React.Component<RadioButtonProps, {}> {
    state = {
        checked: this.props.Checked || false,
    }

    render() {
        return (
            <button
                className={`radioButton--${this.props.State} basic ${
                    this.props.Name ? this.props.Name : ''
                }`}
                onClick={() => {
                    this.setState({
                        ...this.state,
                        checked: !this.state.checked,
                    })
                }}
            >
                <div className="container">
                    <div className="label">
                        <Label Name="iconLabelButton" Text={this.props.Label} />
                    </div>
                    <div className="content">
                        <input
                            type="radio"
                            checked={this.state.checked || false}
                            name={this.props.InputName}
                            value={this.props.InputValue}
                        />
                        <div className='radioIconContainer'>
                            <div className="radioIcon"/>
                        </div>
                    </div>
                </div>
            </button>
        )
    }
}
