import React from 'react'
import { t } from 'i18next'
import { Translation } from '@Entities/Enums'
import { Label } from '../Label/Label'

export interface ProgressBarProps {
    text: string
    percentage: number
}

export class ProgressBar extends React.Component<
    ProgressBarProps,
    {
        currentPercentage: number
    }
> {
    constructor(props: ProgressBarProps) {
        super(props)
        this.state = {
            currentPercentage: this.props.percentage,
        }
    }

    componentDidUpdate(prevProps: Readonly<ProgressBarProps>) {
        if (this.props.percentage !== prevProps.percentage) this.setState({ currentPercentage: this.props.percentage })
    }

    render() {
        return (
            <div className="progressBar">
                <Label Text={t(this.props.text as Translation)} />
                <div className="bar" style={{ width: `${this.state.currentPercentage}%` }}></div>
            </div>
        )
    }
}
