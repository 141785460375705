import React from 'react'

export interface QuotesShuffleProps {
    Quotes: string[]
    Interval: number
}

export class QuotesShuffle extends React.Component<QuotesShuffleProps,{
    currentQuote: string
}> {
    constructor(props: QuotesShuffleProps) {
        super(props)
        this.state = {
            currentQuote: this.props.Quotes[0]
        }
    }

    componentDidMount() {
        setInterval(() => {
            this.setState({
                currentQuote: this.props.Quotes[Math.floor(Math.random() * this.props.Quotes.length)]
            })
        }, this.props.Interval)
    }

    render() {
        return <div className="quotes">
            <div className="quote">
                {this.state.currentQuote}
            </div>
        </div>
    }
}
