import { Highlight, LinkContainer, LinkProps } from '@Containers'
import { Icon, IconVector, Label, ButtonState } from '@Elements'
import { Translation } from '@Entities/Enums'
import React, { MouseEventHandler } from 'react'

export interface IconLabelButtonProps {
    Name?: string
    State: ButtonState
    IconVector: IconVector | string
    Label: Translation | string
    LinkProps?: LinkProps
    OnClick?: MouseEventHandler<HTMLButtonElement>
}

export interface IconLabelButtonStates {
    PreviousState: ButtonState
    State: ButtonState
    Highlighted: boolean
    LinkProps?: LinkProps
    Ref: React.RefObject<HTMLButtonElement>
}

export class IconLabelButton extends React.Component<IconLabelButtonProps, IconLabelButtonStates> {
    buttonState: ButtonState

    constructor(props: IconLabelButtonProps) {
        super(props)
        this.state = {
            PreviousState: this.props.State,
            State: this.props.State,
            Highlighted: false,
            Ref: React.createRef(),
        }

        this.buttonState = this.props.State
    }

    componentDidMount() {
        this.buttonState = this.props.State
    }

    componentDidUpdate() {
        this.buttonState = this.props.State
    }

    onHover: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.buttonState !== ButtonState.BLOCKED) {
            this.buttonState = ButtonState.HOVER

            this.setState({
                State: ButtonState.HOVER,
            })

            if (this.state.State !== ButtonState.BLOCKED) {
                this.setState({
                    PreviousState: this.state.State,
                })
            }
        }
    }
    onLeave: MouseEventHandler<HTMLButtonElement> = () => {
        if (this.buttonState !== ButtonState.BLOCKED) {
            if (this.state.PreviousState !== ButtonState.BLOCKED) {
                this.buttonState = this.state.PreviousState

                this.setState({
                    State: this.state.PreviousState,
                })
            }

            this.setState({
                PreviousState: ButtonState.HOVER,
            })
        }
    }

    render() {
        return (
            <Highlight Highlighted={this.state.Highlighted}  Name="box"
                  Child= {
                    <LinkContainer Name={"LabelButtonLink"} Href={this.state.LinkProps?.Href} Target={this.state.LinkProps?.Target}
                                   Child={
                            <button
                                ref={this.state.Ref}
                                className={`iconLabelButton--${this.buttonState} basic ${
                                    this.props.Name ? this.props.Name : ''
                                }`}
                                onClick={this.props.OnClick}
                                onMouseEnter={this.onHover}
                                onMouseLeave={this.onLeave}
                                disabled={this.buttonState === ButtonState.BLOCKED}
                            >
                                <div className="container">
                                    <div className="label">
                                        <Label Name={`iconLabelButton ${this.props.Name ? this.props.Name : ''}`} Text={this.props.Label} />
                                    </div>
                                    <div className="asset">
                                        <Icon vector={this.props.IconVector} />
                                    </div>
                                </div>
                            </button>
                        }
                    />
                  }
              />)
    }
}
