import { Label } from '@Elements'
import React from 'react'

export interface CaptionProps {
    AvatarMessage: String
    State: CaptionState
    CaptionlessLayout?: boolean
    Name?: string
}

export enum CaptionState {
    ACCESSIBILITY = 'accessibility',
    DISABLED = 'disabled',
    OFF = 'off',
    SOLID = 'solid',
    TRANSPARENT = 'transparent',
}

export class Caption extends React.Component<CaptionProps, {}> {
    render(): React.ReactNode {
        const lessLayout = this.props.CaptionlessLayout
        const mode = this.props.State
        const msg = this.props.AvatarMessage
        const name = this.props.Name || ''
        const isHide = mode === CaptionState.DISABLED || !msg || lessLayout

        if (isHide) {
            return null
        }

        return (
            <div className={`caption ${name}`} data-mode={`${mode}`}>
                <div className={`caption-text`}>
                    <Label Text={msg} />
                </div>
            </div>
        )
    }
}
