import React from 'react'
export interface HighlightProps {
    Name: string
    Highlighted: boolean
    Child: React.ReactNode
}

export class Highlight extends React.Component<HighlightProps, HighlightProps> {
    componentDidUpdate(prevProps: Readonly<HighlightProps>) {
        if (prevProps !== this.props) this.setState({ ...prevProps, ...this.props })
    }

    constructor(props: HighlightProps) {
        super(props)
        this.state = {
            Name: props.Name || '',
            Highlighted: props.Highlighted || false,
            Child: props.Child || null,
        }
    }

    render() {
        return this.state.Child
    }
}
// this.props.Highlighted ? <div className={`highlight ${this.state.Name}`}>{this.state.Child}</div> : this.state.Child
