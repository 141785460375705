import React from 'react'
import { t } from 'i18next'
import { ReactMarkdown } from 'react-markdown/lib/react-markdown'
import { Translation } from '@Entities/Enums'
import { Highlight } from '@Containers'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { I18n } from '@Translation'


export interface LabelProps {
    Name?: string
    Text?: String
    Highlighted?: boolean
    //linkConfig? : LinkConfig
}
export class Label extends React.Component<
    LabelProps,
    {
        Highlighted: boolean
        Ref: React.RefObject<HTMLDivElement>,
        Language: string
    }
> {
    constructor(props: LabelProps) {
        super(props)
        this.state = {
            Highlighted: this.props.Highlighted ? this.props.Highlighted : false,
            Ref: React.createRef(),
            Language: 'en',
        }

        I18n.on('languageChanged', lang => this.setState({ Language: lang }))
    }


    render() {
        if (!this.props.Text) return
    
        return (
            <Highlight
                Highlighted={this.state.Highlighted}
                Name="font"
                Child={
                    <ReactMarkdown rehypePlugins={[rehypeRaw as any]} className={`label ${this.props.Name || 'basic'}`}>
                        {t(this.props.Text as Translation)}
                    </ReactMarkdown>
                }
            />
        )
    }
}
