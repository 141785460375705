import { ElementType } from "@Entities/Enums";
import { AbstractElement } from "@Entities/Abstractions";
import { Expose } from 'class-transformer'
export class IncrementerElement extends AbstractElement {
    @Expose()
    defaultValue: string = ''

    @Expose()
    label: string = ''

    @Expose()
    interval: string = ''

    @Expose()
    from: string = ''

    @Expose()
    to: string = ''

    @Expose()
    name: string = ''

    public elementType : ElementType = ElementType.INCREMENTER;
    public type: string = ElementType.INCREMENTER;

}