import { ElementType, PlayableState } from '@Entities/Enums'
import { Expose } from 'class-transformer'
import { AbstractElement } from '@Entities/Abstractions'
import { ISpeakableElement } from '@Entities/Interfaces'

export class TextElement extends AbstractElement implements ISpeakableElement {

    @Expose()
    name: string = ''

    @Expose()
    elementType: ElementType = ElementType.TEXT

    @Expose()
    type: string = ElementType.TEXT

    @Expose()
    label: string = ''

    @Expose()
    speech: string = ''

    @Expose()
    extraContent: string = '';

    @Expose()
    chat: string = '';

    @Expose()
    id: string = ''

    @Expose()
    mute: boolean = false;

    @Expose()
    state: PlayableState = PlayableState.PENDING

}
