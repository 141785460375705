import { Translation } from '@Entities/Enums'
import { Label, ButtonState } from '@Elements'
import React from 'react'

export interface CheckButtonProps {
    Name?: string
    State: ButtonState
    Label: Translation | string
    OnClick: () => void
    Checked: boolean
}

export class CheckButton extends React.Component<CheckButtonProps, {}> {
    state = {
        checked: this.props.Checked,
    }
    render() {
        return (
            <div
                className={`checkButton--${this.props.State} basic ${this.props.Name ? this.props.Name : ''}`}
                onClick={() => {
                    this.props.OnClick()
                    this.setState({
                        ...this.state,
                        checked: !this.state.checked,
                    })
                }}
            >
                <div className="container">
                    <div className="label">
                        <Label Name="checkButton" Text={this.props.Label} />
                    </div>
                    <div className="content">
                        <input
                            type="checkbox"
                            onChange={(e) => {
                                console.log(e.target.checked)
                            }}
                            checked={this.state.checked || false}
                        />
                        <div className="checkboxContainer">
                            <div className="checkbox" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
