import React, { ChangeEventHandler, MouseEventHandler } from 'react'
import { IconButton, IconVector, Label } from '@Elements'

export interface InputRangeProps {
    Name?: string
    Min?: number
    Max?: number
    Step?: number
    Text?: string
    Value?: number
    OnChangeHandler?: ChangeEventHandler<HTMLInputElement>
    OnClickPrevHandler?: MouseEventHandler<HTMLButtonElement>
    OnClickNextHandler?: MouseEventHandler<HTMLButtonElement>
}

export interface InputRangeStates {
    Text? :string
    Value?: number
}

export class InputRange extends React.Component<InputRangeProps, InputRangeStates> {
    constructor(props) {
        super(props)

        this.state = {
            Text: this.props.Text || '',
            Value: this.props.Value || 0,
        }
    }

    onChangeHandler = e => {
        this.setState({
            Value: e.target.value,
        })

        this.props.OnChangeHandler && this.props.OnChangeHandler(e)
    }

    componentDidUpdate(prevProps: Readonly<InputRangeProps>): void {
        if (prevProps.Value !== this.props.Value) {
            this.setState({          
                Text: this.props.Text,
                Value: this.props.Value,
            });
        }
    }

    render() {
        const min = this.props.Min ? +this.props.Min : -2
        const max = this.props.Max ? +this.props.Max : 2
        const step = this.props.Step ? +this.props.Step : 1
        
        if (min > max) {
            console.warn(`InputRange: min (${min}) is bigger then max (${max})`)

            return;
        }

        return (
            <div className={`input-range-component`}>
                <input
                    type="range"
                    className={`input-range-element ${this.props.Name ? this.props.Name : ''}`}
                    min={min}
                    max={max}
                    step={step}
                    value={this.state.Value}
                    onChange={this.onChangeHandler}
                />
                <div className={`input-range-dots`}>
                    <div className={`input-range-dot`} />
                    <div className={`input-range-dot`} />
                    <div className={`input-range-dot`} />
                    <div className={`input-range-dot`} />
                    <div className={`input-range-dot`} />
                </div>
                <div className={`input-range-legend`}>
                    <div className={`input-range-legend-item input-range-legend-start`}>
                        <IconButton IconVector={IconVector.CIRCLE_MINUS} Name='InputRangeControlBtn' OnClick={this.props.OnClickPrevHandler} />
                    </div>
                    <div className={`input-range-legend-item input-range-legend-middle`}>
                        <Label Text={this.state.Text} />
                    </div>
                    <div className={`input-range-legend-item input-range-legend-end`}>
                        <IconButton IconVector={IconVector.CIRCLE_PLUS} Name='InputRangeControlBtn' OnClick={this.props.OnClickNextHandler} />
                    </div>
                </div>
            </div>
        )
    }
}
