import { UneeqEventObserver } from './UneeqEventObserver'
import { IServices } from '@Entities/Interfaces/IServices'

export class ClientMediaStreamUpdateObserver extends UneeqEventObserver{

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(services: IServices) { super(services) }

    next(): void {
        this.services.UneeqManager.LoadingPercentage = 80
    }

    complete = (): void => console.log('Avatar Ready to Start Observer - executed');
    error = (err: any): void => console.error(`Avatar Ready to Start Observer`, err);
}