import { Stage } from '@Entities/Enums'
import { IServices } from '@Entities/Interfaces/IServices'
import { useState } from 'react'

export function Background (services: IServices) {
        const [stage, setStage] = useState(Stage.INITIAL)   
        services.InterfaceManager.onStageChanged((stage) => { 
            setStage(stage) 
            })
        return (
            <div className={`background ${stage===Stage.INITIAL ? 'initial' : stage===Stage.LOADING ? 'loading' : 'default'}`}>
                <div className="videoContainerOverlay"/>
                <div className="img" />
            </div>
        )
    }
