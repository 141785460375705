import { IServices } from '@Entities/Interfaces/IServices'
import { Observer } from 'rxjs'

export abstract class UneeqEventObserver implements Observer<any> {
    protected services: IServices

    protected constructor(services: IServices) {
        this.services = services;
    }

    abstract next(value: any): void
    abstract complete(): void
    abstract error(err: any): void


}