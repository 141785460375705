import React, { MouseEventHandler } from 'react'
import { Icon, IconVector, Label } from '@Elements'

export interface LanguageSelectionProps {
    Icon?: IconVector
    Label: string
    IsActive?: boolean
    OnClick?: MouseEventHandler
}

export interface LanguagesSelectionProps {
    Languages: LanguageSelectionProps[]
}

export class LanguageSelection extends React.Component<LanguagesSelectionProps, {}> {
    render(): React.ReactNode {
        return (
            <ul className={`language-selection-list`}>
                {this.props.Languages &&
                    this.props.Languages.map((item: LanguageSelectionProps) => (
                        <li
                            key={item.Label}
                            className={`language-selection-item ${item.OnClick ? 'language-selection-item--button' : ''}`}
                            data-active={item.IsActive ? '' : undefined}
                            onClick={item.OnClick}
                        >
                            <div className={`language-selection-icon`}>
                                {item.Icon && (
                                    <Icon vector={item.Icon} />
                                )}
                            </div>
                            <div className={`language-selection-label`}>
                                <Label Text={item.Label} />
                            </div>
                        </li>
                    ))}
            </ul>
        )
    }
}
