import { UneeqAvatarState } from '@Entities/Enums'
import { UneeqEventObserver } from './UneeqEventObserver'
import { IServices } from '@Entities/Interfaces/IServices'

export class AvatarStartedSpeakingObserver extends UneeqEventObserver{

    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(services: IServices) { super(services) }

    next(): void {
        this.services.UneeqManager.avatarState = UneeqAvatarState.SPEAKING
        this.services.HistoryManager.startMessage();
    }

    complete = (): void => console.log('Avatar Started Speaking Observer - executed');
    error = (err: any): void => console.error(`Avatar Started Speaking Observer`, err);
}