import React, { Component, createRef, Key, RefObject } from 'react'
import { ElementType } from '@Entities/Enums'
import {
    Bubble,
    Incrementer,
    LabelButton
} from '@Elements'

import {
    IElement
} from '@Entities/Interfaces'

export interface ContentGroupProps {
    Elements?: ElementReference[];
    Visible?: boolean
}

export interface ElementReference {
    idx: number
    id: Key | null
    type: ElementType,
    elementModel: IElement
    elementReact: React.ReactNode
    getRef: () => LabelButton | Bubble | Incrementer
}

interface ContentGroupState {
    Elements: ElementReference[];
    Visible: boolean
}

export class ContentGroup extends Component<ContentGroupProps, ContentGroupState>
{
    constructor(props: ContentGroupProps) {
        super(props);
        this.state = {
            Visible: this.props.Visible || true,
            Elements: this.props.Elements || [],
        }
    }

    private renderElement = async (type: ElementType, elementModel: IElement, elementReact: React.ReactNode, ref: RefObject<any>): Promise<ElementReference> => {
        const newElementReference: ElementReference =  {
                idx: this.state.Elements.length,
                id: React.isValidElement(elementReact)? elementReact.key : "",
                type,
                elementModel,
                elementReact,
                getRef: () => {
                    if(!ref.current)
                        throw new Error("groupRef.current is null");
                    return ref.current
                }
        }

        return new Promise((resolve) =>
            this.setState(prevState => ({
                Elements: [...prevState.Elements, newElementReference]
            }), () => {
                resolve(newElementReference);
            })
        );
    };

    getAllElementByType = (type: ElementType): ElementReference[] => {
        return this.state.Elements.filter((element) => element.type === type)
    }


    getElement = (element: IElement) : ElementReference | undefined => {
        return this.state.Elements.find((e) => e.id === element.id);
    }

    cleanHighlights = () => {
        const promises: Promise<boolean>[] = []
        this.state.Elements.forEach((element) => {
            const elementRef = element.getRef();
            if (elementRef) {
                promises.push(new Promise<boolean>(resolve => {
                    // @ts-ignore
                    elementRef.setState({ Highlighted: false }, () => {
                        resolve(true)
                    })
                }))
            }
        })
        return Promise.all(promises);
    }

    addElement(params): Promise<ElementReference | undefined> {
        if(!params) return Promise.resolve(undefined);
        const ref = createRef<typeof params.component>();
        const reactElement = React.createElement(params.component as React.ComponentClass<typeof params.component>, { ref, ...params.props });
        return this.renderElement(params.type, params.element, reactElement , ref);
    }

    render() {
        return (
            <div className={`contentGroup`} style={{ display: this.state.Visible ? "grid" : "none" }}>
                {this.state.Elements.map((element) => element.elementReact)}
            </div>
        );
    }
}
