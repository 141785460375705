import React from 'react'

export class Logo extends React.Component<{}, {}> {
    render() {
        return <div className="placeholder">
            &nbsp;
            <div className="logo">{<svg />}</div>
        </div>
    }
}
