import { DigitalHumanConfig } from '@Entities/Models/DigitalHumanConfig'
import { plainToInstance } from 'class-transformer'
import { ConfigFormat } from '@Entities/Enums'

export class ConfigManager {
    public config: DigitalHumanConfig;

    //Singleton
    private static instance: ConfigManager;
    public static getInstance(): ConfigManager {
        if(!ConfigManager.instance)
            ConfigManager.instance = new ConfigManager();
        return ConfigManager.instance
    }

    private constructor() {
        const loadFrom = ConfigFormat.FILE;
        this.config = this.load(loadFrom);

        console.info(`Config Loaded from ${loadFrom}`, [{
            ...this.config.application,
            ...this.config.backend,
            ...this.config.content
        }]);
    }

    public load(format: ConfigFormat): DigitalHumanConfig {
        switch (format) {
            case ConfigFormat.ENVIRONMENT:
                return this.loadFromEnvironment();
            case ConfigFormat.FILE:
                return this.loadFromFile();
            default:
                throw new Error(`Config not found!`);
        }
    }

    private loadFromEnvironment(): DigitalHumanConfig {
        console.info("Loading config from environment");
        return new DigitalHumanConfig();
    }

    private loadFromFile(): DigitalHumanConfig {
        const configPath = `${process.env.PUBLIC_URL}/config.json`;
        const xhr = new XMLHttpRequest();

        // The third parameter 'false' makes the request synchronous.
        //It was forced sync mode to block the application, since config is crucial for the application.
        xhr.open('GET', configPath, false);
        xhr.send();
        return plainToInstance(DigitalHumanConfig, JSON.parse(xhr.responseText));
    }
}