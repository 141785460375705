import { UneeqAvatarState } from '@Entities/Enums'
import { UneeqEventObserver } from './UneeqEventObserver'
import { IServices } from '@Entities/Interfaces/IServices'

export class AvatarFinishSpeakingObserver extends UneeqEventObserver {

    constructor(services: IServices) { super(services) }

    next(value: any): void {
        //If the avatar was speaking, stop it.
        if(this.services.UneeqManager.avatarState === UneeqAvatarState.SPEAKING)
            this.services.UneeqManager.avatarState = UneeqAvatarState.STANDBY;

        this.services.HistoryManager.stopMessage();
    }

    complete = (): void => console.log('Avatar Stop Speaking Observer - executed');
    error = (err: any): void => console.error(`Avatar Stop Speaking Observer`, err);
}