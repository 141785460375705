import RecordRTC, { StereoAudioRecorder } from 'recordrtc'
import { IAudioRecordData } from '@Entities/Interfaces/IAudioRecordData'
import { filter, fromEvent, identity, OperatorFunction, Subject } from 'rxjs'
import { RefObject } from 'react'
import { EventName } from '@Entities/Enums'

export class AudioRecordManager {
    private mediaStream?: MediaStream;
    private recorder?: RecordRTC;
    private dataObservable = new Subject<IAudioRecordData>();

    private static instance: AudioRecordManager;
    public static getInstance(): AudioRecordManager {
        if(!AudioRecordManager.instance)
            AudioRecordManager.instance = new AudioRecordManager();
        return AudioRecordManager.instance
    }
    private constructor() {}

    onDataGenerated(callback: (nextPendingRecord: IAudioRecordData) => void): void {
        this.dataObservable.subscribe(callback)
    }

    // Request access to the microphone
    async getDevice(): Promise<MediaStream> {
        if (!this.mediaStream)
            this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true })

        if (!this.mediaStream)
            throw new Error('No microphone found')

        return this.mediaStream
    }

    private async getRecorder(): Promise<RecordRTC> {
        if (!this.recorder) {
            this.recorder = new RecordRTC((await this.getDevice()).clone(), {
                type: 'audio',
                mimeType: 'audio/webm',
                recorderType: StereoAudioRecorder,
                numberOfAudioChannels: 1,
                disableLogs: true
            })
        }
        return this.recorder;
    }


    private registerEvent(ref: RefObject<HTMLElement | Document>, eventName:EventName,  pipe: OperatorFunction<Event, Event>, callback: () => void): void {
        if(!ref.current)
            throw new Error('ref is not defined')

        fromEvent(ref.current, eventName).pipe(pipe).subscribe({
            next: (event:Event) => {
                callback();
            },
            error: (err:any) => console.error('Observer got an error: ' + err)
        })
    }

    startRecordWhen = (ref:RefObject<HTMLElement | Document>, eventName:EventName[], pipe: OperatorFunction<Event, Event> = identity) => {
        eventName.forEach( eventName =>
            this.registerEvent(ref, eventName, pipe, () => this.startRecording())
        );
    }

    stopRecordWhen = (ref:RefObject<HTMLElement | Document>, eventName:EventName[], pipe: OperatorFunction<Event, Event> = identity) => {
        eventName.forEach(eventName =>
            this.registerEvent(ref, eventName, pipe,() => this.stopRecording())
        );
    }

    private startRecording(): void {
        this.getRecorder().then(recorder => {
            recorder.state !== "recording" && recorder.startRecording()
        });
    }

    private async stopRecording(): Promise<{audio:{type:string, dataURL:string}}> {
        return new Promise(async (resolve, reject) => {
            (await this.getRecorder()).stopRecording(() => {
                this.recorder?.getDataURL(data => {
                    this.dataObservable.next({
                        audio: {
                            type: 'audio/wav',
                            dataURL: data
                        }
                    })
                    this.recorder?.destroy()
                    this.recorder = undefined
                })
            })
        })
    }

    public registerKeyboardEvents(ref:RefObject<HTMLElement | Document>){

        // Monitor Keyboard (Space)
        const spaceKeyDownPipe: OperatorFunction<Event, Event> = event => {
            return event.pipe(
                filter((event: Event) => {
                    //If it is a keyboard event and space is pressed not repeated
                    const isKeyboardEventAndSpaceNotRepeated = event instanceof KeyboardEvent && event.code === 'Space' && !event.repeat;
                    //If it is a keyboard event but is not triggered by a text input
                    const isNotTextInput = !(event.target instanceof HTMLInputElement && event.target.type === 'text')

                    return isKeyboardEventAndSpaceNotRepeated && isNotTextInput
                }),
            )
        };

        this.startRecordWhen(ref, [EventName.KEY_DOWN], spaceKeyDownPipe);
        this.stopRecordWhen(ref, [EventName.KEY_UP], spaceKeyDownPipe);
    }

    public registerMouseEvents(ref:RefObject<HTMLElement | Document>|undefined){
        if(!ref) throw new Error('ref is not defined')

        this.startRecordWhen(ref,[EventName.MOUSE_DOWN, EventName.TOUCH_START]);
        this.stopRecordWhen(ref,[EventName.MOUSE_UP, EventName.TOUCH_END]);
    }

}