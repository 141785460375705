import { DataType } from '@Entities/Enums/DataType'
import { Expose } from 'class-transformer'
import { Message } from '@Entities'

export class UserMessage {
    @Expose({name: 'page'})
    page?: string

    @Expose({ name: 'dataType' })
    dataType?: DataType;

    @Expose({ name: 'data' })
    data?: string | Message;

    @Expose({ name: 'sessionId' })
    sessionId?: string;

    @Expose({ name: 'language' })
    language?: string;

}