import { Opacity } from '@Entities/Enums'
import React from 'react'

export interface VideoContainerProps {
    LocalVideoRef: React.RefObject<HTMLDivElement>
    AvatarVideoRef: React.RefObject<HTMLDivElement>
    OnClick: () => void
}

export class VideoContainer extends React.Component<VideoContainerProps, {}> {
    render() {
        return (
            <div className="videoContainer" onClick={this.props.OnClick}>
                <div className="videoContainerOverlay"></div>
                <div className="seamless"></div>
                <div ref={this.props.AvatarVideoRef} className="avatarVideo"></div>
                <div ref={this.props.LocalVideoRef} className="localVideo"></div>
            </div>
        )
    }
}
