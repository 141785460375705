import React, { useEffect, useState } from 'react'
import { DigitalHumanContainer, MainContainer } from '@Containers'
import { t } from 'i18next'
import { QuotesShuffle, ProgressBar} from '@Elements'
import { IServices } from '@Entities/Interfaces/IServices'
import { DigitalHumanState, Stage, Translation } from '@Entities/Enums'

export function LoadingController(services: IServices) {
    const [containerVisibility, setContainerVisibility] = useState(false)
    const [percentage, setPercentage] = useState(0)

    useEffect(() => {
        services.InterfaceManager.onStageChanged((state) => {
            setContainerVisibility(state === Stage.LOADING)
        })
        services.UneeqManager.onLoadingPercentageChange((percentage) => setPercentage(percentage))
    })
    return (
        containerVisibility ? 
        <DigitalHumanContainer State={DigitalHumanState.CHAT}>
            <MainContainer
                Name={'LoadingController'}
                Visible={containerVisibility}
                Top={[]}
                Main={[]}
                Bottom={[
                    // <QuotesShuffle Quotes={services.ConfigManager.config.content.LoadingMessages} Interval={2000} />,
                    <QuotesShuffle Quotes={[`${services.ConfigManager.config.content.AvatarName} ${t(Translation.LOADING_PAGE_LOADING_TEXT)}`]} Interval={2000} />,
                    <ProgressBar
                        text={`${services.ConfigManager.config.content.AvatarName} ${t(Translation.LOADING_PAGE_LOADING_BAR)}`}
                        percentage={percentage}
                    />,
                ]}
            />
        </DigitalHumanContainer>
        : null 
    )
}
