import { UneeqEventObserver } from './UneeqEventObserver'
import { IServices } from '@Entities/Interfaces/IServices'
import { Stage } from '@Entities/Enums'

export class AvatarSessionLive extends UneeqEventObserver{
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(services: IServices) { super(services) }

    next(): void {
        this.services.UneeqManager.LoadingPercentage = 100;

        if(this.services.ConfigManager.config.content.WelcomeEvent)
            this.services.BackendManager.sendEvent(this.services.ConfigManager.config.content.WelcomeEvent, this.services.UneeqManager.getSessionId() as string);

        //To allow the animation to play
        setTimeout(() => {
            this.services.InterfaceManager.Stage = Stage.DIGITAL_HUMAN
        }, 1000);
    }

    complete = (): void => console.log('Avatar Session Live Observer - executed');
    error = (err: any): void => console.error(`Avatar Session Live Observer`, err);
}