import ReactDOM from 'react-dom/client'
import '@Themes'
import { I18n } from '@Translation'
import { Application } from '@Application'
import 'reflect-metadata'
I18n.init();

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Application />
)
