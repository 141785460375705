import { ElementType, PlayableState } from '@Entities/Enums'
import { Expose } from 'class-transformer'
import { ISpeakableElement } from '@Entities/Interfaces'
import { AbstractElement } from '@Entities/Abstractions'
import { ILinkElement } from '@Entities/Interfaces/ILinkElement'

export class ButtonElement extends AbstractElement implements ISpeakableElement, ILinkElement  {

    @Expose()
    name: string = ''

    @Expose()
    isPlayableEntity: boolean = true;

    @Expose()
    addPause: boolean = false;

    @Expose()
    id: string = ""

    @Expose()
    mute: boolean = true;

    @Expose()
    speech: string = ""

    @Expose()
    state: PlayableState = PlayableState.PENDING;

    @Expose()
    duration: number = 0;

    @Expose()
    label: string = '';

    @Expose()
    icon: string = '';

    @Expose()
    iconURL: string = '';

    @Expose()
    event: string = '';

    @Expose()
    elementType: ElementType = ElementType.BUTTON

    @Expose()
    type: string = ElementType.BUTTON;

    @Expose()
    href: string = '';

    @Expose()
    target: string = '';
}

