import 'reflect-metadata'
import {
    AudioRecordManager,
    BackendManager,
    ConfigManager,
    HistoryManager,
    InterfaceManager,
    SettingManager,
    UneeqManager,
} from '@Services'
import React, { useState } from 'react'
import { IServices } from '@Entities/Interfaces/IServices'
import { InitialController } from '@Application/Controllers/InitialController'
import { LoadingController } from '@Application/Controllers/LoadingController'
import { DigitalHumanController } from '@Application/Controllers/DigitalHumanController'
import { UneeqEvent } from '@Entities/Enums'
import {
    AvatarAnswerObserver,
    AvatarFinishSpeakingObserver,
    AvatarStartedSpeakingObserver,
    ClientMediaStreamUpdateObserver,
    AvatarReadyObserver,
    AvatarSessionLive,
} from '@Observers'
import { AvailableLanguages, I18n } from '@Translation'
import { Background } from '@Elements'
import { SpeechTranscriptionObserver } from '@Application/Observers/SpeechTranscriptionObserver'

export function Application() {
    const configManager = ConfigManager.getInstance()
    const interfaceManager = InterfaceManager.getInstance()
    const historyManager = HistoryManager.getInstance()
    const audioRecordManager = AudioRecordManager.getInstance()
    const uneeqManager = UneeqManager.getInstance()
    const backendManager = BackendManager.getInstance()
    const settingsManager = SettingManager.getInstance()

    const [services] = useState<IServices>({
        InterfaceManager: interfaceManager,
        BackendManager: backendManager,
        HistoryManager: historyManager,
        AudioRecordManager: audioRecordManager,
        UneeqManager: uneeqManager,
        ConfigManager: configManager,
        SettingManager: settingsManager,
    })

    const urlSearchParams = new URLSearchParams(window.location.search);
    I18n.changeLanguage(AvailableLanguages.find(l => l === urlSearchParams.get('lang')) || services.ConfigManager.config.backend.DefaultLanguage).then(lang => {
    })

    // Monitor Messages from the Uneeq
    services.UneeqManager.subscribeEventObserver(UneeqEvent.READY, new AvatarReadyObserver(services))
    services.UneeqManager.subscribeEventObserver(
        UneeqEvent.CLIENT_MEDIA_STREAM_UPDATE,
        new ClientMediaStreamUpdateObserver(services)
    )

    services.UneeqManager.subscribeEventObserver(UneeqEvent.SESSION_LIVE, new AvatarSessionLive(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.AVATAR_ANSWER, new AvatarAnswerObserver(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.STARTED_SPEAKING, new AvatarStartedSpeakingObserver(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.FINISHED_SPEAKING,new AvatarFinishSpeakingObserver(services))
    services.UneeqManager.subscribeEventObserver(UneeqEvent.SPEECH_TRANSCRIPTION, new SpeechTranscriptionObserver(services))

    return (
        <>  
            <Background {...services}/>
            <InitialController {...services} />
            <LoadingController {...services} />
            <DigitalHumanController {...services} />
        </>
    )
}
