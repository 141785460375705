import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

// ToDo: Add this programatically
import de from './de.json'
import en from './en.json'
import fr from './fr.json'
import it from './it.json'

export const resources = {
    en,
    de,
    fr,
    it,
} as const

export const AvailableLanguages = Object.keys(resources)

i18n.use(initReactI18next).use(LanguageDetector).init({
    resources,
    defaultNS: 'common',
    fallbackLng: 'en'
})

// @ts-ignore
export const I18n = i18n;