import React, { useState, useRef } from 'react'
import { IServices } from '@Entities/Interfaces/IServices'
import {
    ButtonState,
    Caption,
    CaptionState,
    Carousel,
    Dropdown,
    DropdownAlignment,
    DropdownState,
    IconButton,
    IconLabelButton,
    IconVector,
    InterfaceSize,
    Label,
    LabelButton,
    LanguageSelection,
} from '@Elements'
import { Bar } from '@Containers'
import { I18n } from '@Translation'
import { Translation } from '@Entities/Enums'

export enum DropdownTemplate {
    SETTINGS = 'settings',
    INTERFACE_SIZE = 'interfaceSize',
    INTERFACE_SIZE_NO_BAR = 'interfaceSizeNoBar',
    CAPTION_STYLE = 'captionStyle',
    PRIVACY_POLICY = 'privacyPlicy',
    LANGUAGE_SELECTION = 'languageSelection',
}
export interface DrodownControllerProps {
    Template: DropdownTemplate
    Icon: IconVector
    Services: IServices
    Alignment?: DropdownAlignment
    IconName?: string
}

export function DropdownController(props: DrodownControllerProps) {
    const [dropdownContentActive, setDropdownContentActive] = useState(props.Template)
    const Services = props.Services
    const dropdownRef: any = useRef()

    return (
        <Dropdown
            Icon={props.Icon}
            IconName={props.IconName}
            Alignment={props.Alignment}
            OnClickBeforeOpen={(e) => setDropdownContentActive(props.Template)}
            ref={dropdownRef}
        >
            {dropdownContentActive === DropdownTemplate.SETTINGS && (
                <>
                    <LanguageSelection
                        key="LanguageSelectionText"
                        Languages={[
                            {
                                Icon: IconVector.TEXT_SIZE,
                                Label: Translation.DROPDOWN_CONTROLLER_INTERFACE_SIZE,
                                OnClick: (e) => setDropdownContentActive(DropdownTemplate.INTERFACE_SIZE),
                            },
                            {
                                Icon: IconVector.A_UNDERSCORE,
                                Label: Translation.DROPDOWN_CONTROLLER_CAPTION_STYLE,
                                OnClick: (e) => setDropdownContentActive(DropdownTemplate.CAPTION_STYLE),
                            },
                            {
                                Icon: IconVector.LOCK,
                                Label: Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY,
                                OnClick: (e) => setDropdownContentActive(DropdownTemplate.PRIVACY_POLICY),
                            },
                            // {
                            //     Label: `Version n° ${version}`,
                            // }
                        ]}
                    />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.LANGUAGE_SELECTION && (
                <>
                    <LanguageSelection
                        key="LanguageSelectionFlags"
                        Languages={[
                            {
                                Label: 'Deutsch',
                                OnClick: e => {
                                    I18n.changeLanguage('de')

                                    dropdownRef.current.changeState(DropdownState.CLOSE)
                                },
                            },
                            {
                                Label: 'Français',
                                OnClick: e => {
                                    I18n.changeLanguage('fr')

                                    dropdownRef.current.changeState(DropdownState.CLOSE)
                                },
                            },
                            {
                                Label: 'Italiano',
                                OnClick: e => {
                                    I18n.changeLanguage('it')

                                    dropdownRef.current.changeState(DropdownState.CLOSE)
                                },
                            },
                        ]}
                    />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.INTERFACE_SIZE && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={(e) => setDropdownContentActive(DropdownTemplate.SETTINGS)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={Translation.DROPDOWN_CONTROLLER_INTERFACE_SIZE} Name="topbar-label" />,
                        ]}
                    />
                    <InterfaceSize Services={Services} />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.INTERFACE_SIZE_NO_BAR && (
                <>
                    <InterfaceSize Services={Services} />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.CAPTION_STYLE && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={(e) => setDropdownContentActive(DropdownTemplate.SETTINGS)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={Translation.DROPDOWN_CONTROLLER_CAPTION_STYLE} Name="topbar-label" />,
                        ]}
                    />
                    <Carousel
                        onSlideChange={(slideActive) => (Services.SettingManager.captionStyle = slideActive.value)}
                        slides={[
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.DISABLED}
                                        State={CaptionState.OFF}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.DISABLED,
                                isActive: Services.SettingManager.captionStyle === CaptionState.DISABLED,
                            },
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.TRANSPARENT}
                                        State={CaptionState.TRANSPARENT}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.TRANSPARENT,
                                isActive: Services.SettingManager.captionStyle === CaptionState.TRANSPARENT,
                            },
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.SOLID}
                                        State={CaptionState.SOLID}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.SOLID,
                                isActive: Services.SettingManager.captionStyle === CaptionState.SOLID,
                            },
                            {
                                content: (
                                    <Caption
                                        AvatarMessage={CaptionState.ACCESSIBILITY}
                                        State={CaptionState.ACCESSIBILITY}
                                        Name="toppy"
                                    />
                                ),
                                value: CaptionState.ACCESSIBILITY,
                                isActive: Services.SettingManager.captionStyle === CaptionState.ACCESSIBILITY,
                            },
                        ]}
                    />
                </>
            )}
            {dropdownContentActive === DropdownTemplate.PRIVACY_POLICY && (
                <>
                    <Bar
                        Name="dropdown-topbar"
                        Left={[
                            <IconButton
                                IconVector={IconVector.ARROW_LEFT}
                                OnClick={(e) => setDropdownContentActive(DropdownTemplate.SETTINGS)}
                                Name="DropdownTopbarIconButton"
                            />,
                            <Label Text={Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY} Name="topbar-label" />,
                        ]}
                    />
                    <div className={`dropdown-content`}>
                        <IconLabelButton
                            IconVector={IconVector.NEW_TAB}
                            Label={Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY_LINK}
                            State={ButtonState.DEFAULT}
                            Name="privacy-policy-link"
                        />
                        <LabelButton
                            Label={Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY_WITHDRAW}
                            State={ButtonState.DEFAULT}
                            Name="editorial"
                            OnClick={() => window.location.reload()}
                        />
                        <Label
                            Text={Translation.DROPDOWN_CONTROLLER_PRIVACY_POLICY_SUBTEXT}
                            Name="privacy-policy-subtext"
                        />
                    </div>
                </>
            )}
        </Dropdown>
    )
}
