import React from 'react'
import { InputRange } from '@Elements'
import { IServices } from '@Entities/Interfaces/IServices'

export interface InterfaceSizeProps {
    Services: IServices
    Text?: string
    Value?: number
}

export interface InterfaceSizeStates {
    Text: string
    Value: number
}

export class InterfaceSize extends React.Component<InterfaceSizeProps, InterfaceSizeStates> {
    constructor(props) {
        super(props)

        this.state = {
            Text: this.props.Text || '100%',
            Value: this.props.Value || this.props.Services.SettingManager.interfaceSize || 0,
        }
    }

    componentDidMount(): void {
        this.props.Services.SettingManager.interfaceSize = this.state.Value

        // @todo remove it after interfaceSize listining problem resolved
        document.body.parentElement &&
            (document.body.parentElement.style.fontSize = this.props.Services.SettingManager.interfaceSizeStyle)
    }

    componentDidUpdate(): void {
        this.props.Services.SettingManager.interfaceSize = this.state.Value

        // @todo remove it after interfaceSize listining problem resolved
        document.body.parentElement &&
            (document.body.parentElement.style.fontSize = this.props.Services.SettingManager.interfaceSizeStyle)
    }

    render(): React.ReactNode {
        return (
            <InputRange
                Text={this.state.Text}
                Value={this.state.Value}
                OnChangeHandler={(e) => {
                    this.setState({
                        Value: +e.target.value,
                        Text: `${(100 + +e.target.value * 25)}%`,
                    })
                }}
                OnClickPrevHandler={(e) => {
                    if (this.state.Value > -2) {
                        this.setState({
                            Text: `${+this.state.Text.slice(0, -1) - 25}%`,
                            Value: this.state.Value - 1,
                        })
                    }
                }}
                OnClickNextHandler={(e) => {
                    if (this.state.Value < 2) {
                        this.setState({
                            Text: `${+this.state.Text.slice(0, -1) + 25}%`,
                            Value: this.state.Value + 1,
                        })
                    }
                }}
            />
        )
    }
}
